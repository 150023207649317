<template>
    <div class="icon" @click="openTurboSearch">
        <el-tooltip :enterable="false" :openDelay="500" effect="dark" content="CTRL + S" placement="bottom">
            <fa-icon :icon="['far', 'search']" class="mr-3 mt-1 text-xl cursor-pointer text-gray-600" />
        </el-tooltip>
    </div>
</template>
<script>
export default {
    methods: {
        openTurboSearch() {
            this.$emit('clicked');
        },
    },
};
</script>
<style scoped>
    .icon {
        margin-top: -4px;
    }
</style>
